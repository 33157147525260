// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-bookblog-js": () => import("./../src/components/bookblog.js" /* webpackChunkName: "component---src-components-bookblog-js" */),
  "component---src-components-one-book-js": () => import("./../src/components/OneBook.js" /* webpackChunkName: "component---src-components-one-book-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-books-js": () => import("./../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-penjinglist-js": () => import("./../src/pages/penjinglist.js" /* webpackChunkName: "component---src-pages-penjinglist-js" */),
  "component---src-pages-products-js": () => import("./../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-writingpad-js": () => import("./../src/pages/writingpad.js" /* webpackChunkName: "component---src-pages-writingpad-js" */)
}

